import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { useLocation } from "react-router-dom";
import { Animate, ViewContext, Card, Grid, useWindowSize } from 'components/lib';
import { EmailSetup } from './email-setup';
import { currency } from 'utilities/currency';
import Style from './reports.module.scss';
import moment from 'moment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx-color';

import { Button, Col, DatePicker, Row, Space, Table, Divider } from 'antd';

export function Reports() {
    const context = useContext(ViewContext);
    const location = useLocation();
    const { width } = useWindowSize();


    const [dealsLoading, setDealsLoading] = useState(false);
    const [dailyNomLoading, setDailyNomLoading] = useState(false);
    const [flowDateOptions, setFlowDateOptions] = useState(undefined);
    const [selectedFlowDate, setSelectedFlowDate] = useState(undefined);
    const [spotDeals, setSpotDeals] = useState([]);
    const [longTermDeals, setLongTermDeals] = useState([]);
    const [dailyNomination, setDailyNomination] = useState(undefined);
    const [noDailyNomination, setNoDailyNomination] = useState(false);

    const getOptionData = useCallback((data) => data.map(obj => ({
        label: obj.name,
        value: obj.id
    })), []);

    useEffect(() => {
        if(location && location.state && location.state.flowDate) setSelectedFlowDate(location.state.flowDate);
    }, [location]);

    useEffect(() => {
        const getCompletedFlowDates = async () => {
            try {
                const res = await Axios.get('/api/flow-date', { params: { pending: false } });
          
                if (res.status === 200) {
                    setFlowDateOptions(res.data.data);
                }          
              }
              catch (err){
                context.handleError(err);
              }
        };

        if(!flowDateOptions) getCompletedFlowDates();
    }, [flowDateOptions, getOptionData]);

    useEffect(() => {
        // get deals
        const getDeals = async () => {
            try {
                setDealsLoading(true);
                const flowDateData = flowDateOptions.find(obj => obj.id === selectedFlowDate);
                const dateISO = moment(flowDateData.date).startOf('day').toISOString();
                const spotDealRes = await Axios.get('/api/deal', {  params: { from: dateISO, deal_type: 0 } });
                const longDealRes = await Axios.get('/api/deal', {  params: { from: dateISO, deal_type: 2 } });

                if (spotDealRes.status === 200 && longDealRes.status === 200) {
                    setSpotDeals(spotDealRes.data.data);
                    setLongTermDeals(longDealRes.data.data);
                    setDealsLoading(false);
                }          
              }
              catch (err){
                context.handleError(err);
                setDealsLoading(false);
              }
        };

        // get daily nominations
        const getDailyNominations = async () => {
            try {
                setDailyNomLoading(true);
                const res = await Axios.get('/api/daily-nomination', {  params: { flow_date_id: selectedFlowDate } });
          
                if (res.status === 200) {
                    if(res.data.data) {
                        setDailyNomination(res.data.data);
                    } else {
                        setNoDailyNomination(true);
                    }
                    setDailyNomLoading(false);
                }          
              }
              catch (err){
                context.handleError(err);
                setDailyNomLoading(false);
              }
        };

        if(selectedFlowDate && flowDateOptions && flowDateOptions.length > 0) {
            getDeals();
            getDailyNominations();
        }

    }, [flowDateOptions, selectedFlowDate]);

    const onFlowDateChange = (value) => {
        if(!value) {
            setSelectedFlowDate(undefined);
            setSpotDeals([]);
            setLongTermDeals([]);
            setDailyNomination(undefined);
        } else {
            const flowDateObj = getMatchingDate(value);
            setSelectedFlowDate(flowDateObj.id);
        }
    }

    const getFlowDateObj = () => flowDateOptions.find(obj => obj.id === selectedFlowDate);
    const getFlowDateLabel = () => moment(getFlowDateObj().date).format('MM/DD/YYYY');

    const getCurrentMidpointForMultiSpotDeal = (entry) => {
        const flowDateObj = getFlowDateObj();
        const formattedFlowDate =  moment(flowDateObj.date).format('YYYY-MM-DD');

        return entry.midpoints.find(mp => moment(formattedFlowDate).isSame(moment(mp.flow_date)));
    }

    const prepareColumnRes = (columns, floatColumn, mediumScreenWidth) => {
        if (mediumScreenWidth) {
            if (columns[columns.length - 1]['key'] === floatColumn) {
                columns[columns.length - 1]['fixed'] = 'right';
            } else {
                delete columns[columns.length - 1]['fixed'];
            } 
        } 
        return columns
    }

    const getDealsList = (isLongTerm) => {
        const columns = [
            {
                title: isLongTerm ? 'Long Term Deal' : 'Spot Deal',
                render: (record, index) => {

                    return (
                        <Fragment>
                        Quantity: {record.volume}
                        <br />
                        Base Price: {record.base_price}
                        <br />
                        Midpoint: {record.midpoint === null || record.midpoint === undefined ? 'N/A' : currency(record.midpoint)}
                        <br />
                        Final Price: {record.final_price === null || record.final_price === undefined ? 'N/A' : currency(record.final_price)}
                        <br />
                        Total: {record.final_price}
                        Pipeline: {record.pipeline}
                        <br />
                        Position: {record.position}
                        <br />
                        Location: {record.point}
                        <br />
                        Supplier: {record.company}
                    </Fragment>
                    );
                },
                responsive: ["xs"]
            },
            {
              title: 'Quantity',
              dataIndex: 'volume',
              key: 'volume',
              sorter: (a, b) => a.volume - b.volume,
              responsive: ["sm"]
            },
            {
                title: 'Base Price',
                dataIndex: 'base_price',
                key: 'base_price',
                render: (base_price) => currency(base_price, 2),
                sorter: (a, b) => a.base_price - b.base_price,
                responsive: ["sm"]
            },
            {
                title: 'MidPoint',
                dataIndex: 'midpoint',
                key: 'midpoint',
                render: (midpoint, entry) => {
                    
                    if (!entry.isLongTerm && !entry.static_midpoint && entry.midpoints.length) {
                        const currentMidpointObj = getCurrentMidpointForMultiSpotDeal(entry);
                        return currentMidpointObj ? currency(currentMidpointObj?.midpoint, 3) : 'N/A';
                    }

                    return (entry.is_long_term && entry.fix_price && midpoint) || (!entry.is_long_term && midpoint !== null)  ? currency(midpoint) : 'N/A'
                },
                sorter: (a, b) => a.midpoint - b.midpoint,
                responsive: ["sm"]
            },
            {
                title: 'Fix Price',
                dataIndex: 'fix_price',
                key: 'fix_price',
                render: (fix_price, entry) => {
                    const fixPriceData = entry.is_long_term ? entry.fix_price_slot : fix_price;
                    return fixPriceData ? currency(fixPriceData) : '$0.00';
                },
                sorter: (a, b) => a.fix_price - b.fix_price,
                responsive: ["sm"]
            },
            {
                title: 'Total',
                dataIndex: 'final_price',
                key: 'final_price',
                render: (final_price, entry) => {
                    
                    if (!entry.isLongTerm && !entry.static_midpoint && entry.midpoints.length) {
                        const currentMidpointObj = getCurrentMidpointForMultiSpotDeal(entry);
                        return currentMidpointObj ? currency(currentMidpointObj?.final_price) : 'N/A';
                    }

                    return final_price === null || final_price === undefined ? 'N/A' : currency(final_price)
                },
                sorter: (a, b) => a.final_price - b.final_price,
                responsive: ["sm"]
            },
            {
                title: 'Pipeline',
                dataIndex: 'pipeline',
                key: 'pipeline',
                sorter: (a, b) => a.pipeline.toLowerCase().localeCompare(b.pipeline.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Position',
                dataIndex: 'position',
                key: 'position',
                sorter: (a, b) => a.position.toLowerCase().localeCompare(b.position.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Location',
                dataIndex: 'point',
                key: 'point',
                sorter: (a, b) => a.point.toLowerCase().localeCompare(b.point.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Supplier',
                dataIndex: 'company',
                key: 'company',
                sorter: (a, b) => a.company.toLowerCase().localeCompare(b.company.toLowerCase()),
                responsive: ["sm"]
            }
        ];

        return (
            <div className={Style.dealListWrap}>
                <Table 
                    columns={columns} 
                    dataSource={isLongTerm ? longTermDeals : spotDeals}
                    bordered 
                />
            </div>
        );
    }


    const getDailyNominationPipelineRecordList = (plRecordData) => {
        if(plRecordData.length) {

            const columns = [
                {
                    render: (record) => {

                        return (
                            <Fragment>
                                Quantity: {record.quantity}
                                <br />
                                Location: {record.location}
                                <br />
                                Name: {record.name}
                                <br />
                                Contract Num: {record.contract_num}
                                <br />
                                Receipt Location Name: {record.receipt_location_name}
                                <br />
                                Package Id: {record.package_id}
                            </Fragment>
                        );
                    },
                    responsive: ["xs"]
                },
                {
                    title: 'Quantity',
                    dataIndex: 'quantity',
                    key: 'quantity',
                    sorter: (a, b) => a.quantity - b.quantity,
                    responsive: ["sm"]
                },
                {
                    title: 'Location',
                    dataIndex: 'location',
                    key: 'location',
                    sorter: (a, b) => a.location.toLowerCase().localeCompare(b.location.toLowerCase()),
                    responsive: ["sm"]
                },
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
                    responsive: ["sm"]
                },
                {
                    title: 'Contract Num',
                    dataIndex: 'contract_num',
                    key: 'contract_num',
                    sorter: (a, b) => a.contract_num.toLowerCase().localeCompare(b.contract_num.toLowerCase()),
                    responsive: ["sm"]
                },
                {
                    title: 'Receipt Location Name',
                    dataIndex: 'receipt_location_name',
                    key: 'receipt_location_name',
                    sorter: (a, b) => a.receipt_location_name.toLowerCase().localeCompare(b.receipt_location_name.toLowerCase()),
                    responsive: ["sm"]
                },
                {
                    title: 'Package Id',
                    dataIndex: 'package_id',
                    key: 'package_id',
                    sorter: (a, b) => a.package_id.toLowerCase().localeCompare(b.package_id.toLowerCase()),
                    responsive: ["sm"]
                },
            ];

            const mediumScreenWidth = width && (width < 940 && width > 574);

            return (
                <div className={Style.pipelineRecordList}>
                    <Table 
                    columns={prepareColumnRes(columns, 'quantity', mediumScreenWidth)} 
                    dataSource={plRecordData}
                    bordered 
                    pagination={false}
                    style={{ paddingBottom: '20px'}}
                    scroll={{ x: mediumScreenWidth ? 1300 : undefined }}
                />
                </div>
            );
        }
        
        return null;
    }

    const getPipelineQuantityTotal = () => {
        let total = 0;

        if(dailyNomination.length) {

            dailyNomination.forEach(dailyNom => {
                dailyNom.pipelineRecords.forEach(pipelineRecord => {
                    total = total + pipelineRecord.quantity;
                });
            });
            
        }

        return total;
    }

    const getAllDeals = () => [...spotDeals, ...longTermDeals];

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = () => {
        const fileName = 'deal-and-daily-nomination-report';

        const dnData = [];
        const pipelineRecordsOriginArr = [];

        let rowEndBump;


        dailyNomination.forEach((dailyNom, idx) => {
            const rowData = {
                dailyNominationHeaderJson: { r: 0, c: 0 },
                pipelineRecordsData: { r: 0, c: 0 }
            };

            const dailyNominationHeaderJson = [{
                company: dailyNom.name,
                flowDateLabel: 'Flow Date:',
                flowDate: getFlowDateLabel()
            }];

            rowData.dailyNominationHeaderJson.r = (idx === 0) ? 0 : pipelineRecordsOriginArr[idx - 1]['dailyNominationHeaderJson']['r'] + dailyNom.pipelineRecords.length + 3;

            const pipelineRecordsData = dailyNom.pipelineRecords.map(pipelineRecord => ({
                Pipeline: pipelineRecord.name,
                Location: pipelineRecord.location,
                Quantity: pipelineRecord.quantity
            })); 

            if (idx === (dailyNomination.length - 1)) rowEndBump = pipelineRecordsData.length;

            rowData.pipelineRecordsData.r = rowData.dailyNominationHeaderJson.r + 1;

            dnData.push({
                dailyNominationHeaderJson,
                pipelineRecordsData
            });

            pipelineRecordsOriginArr.push(rowData);
        });

        const totalCellRowPos = pipelineRecordsOriginArr[dailyNomination.length - 1]['pipelineRecordsData']['r'] + rowEndBump + 1;
        
        const pipelineQuantityTotalOrigin = {
            r: totalCellRowPos,
            c: 1
        }

        const pipelineQuantityTotal = [{
            totalFlowLabel: 'Total Flow:',
            total: getPipelineQuantityTotal()
        }];

        const dealsOrigin = {
            r: totalCellRowPos + 2,
            c: 0
        }

        const dealsData = getAllDeals().map(deal => ({
            Position: deal.position,
            Pipeline: deal.pipeline,
            Total: deal.final_price,
            Quantity: deal.volume,
            Location: deal.point,
            Supplier: deal.company
        }));

        let ws;

        dnData.forEach((dn, idx) => {

            if(idx === 0) {
                ws = XLSX.utils.json_to_sheet(dn.dailyNominationHeaderJson, {
                    skipHeader: true
                });

                XLSX.utils.sheet_add_json(ws, dn.pipelineRecordsData, {
                    origin: pipelineRecordsOriginArr[idx]['pipelineRecordsData']
                });
            } else {
                XLSX.utils.sheet_add_json(ws, dn.dailyNominationHeaderJson, {
                    skipHeader: true,
                    origin: pipelineRecordsOriginArr[idx]['dailyNominationHeaderJson']
                });

                XLSX.utils.sheet_add_json(ws, dn.pipelineRecordsData, {
                    origin: pipelineRecordsOriginArr[idx]['pipelineRecordsData']
                });
            }    
            
        });

        XLSX.utils.sheet_add_json(ws, pipelineQuantityTotal, {
            origin: pipelineQuantityTotalOrigin,
            skipHeader: true
        });

        XLSX.utils.sheet_add_json(ws, dealsData, {
            origin: dealsOrigin
        });

        ws['!cols'] = [ { width: 20 }, { width: 20 }, { width: 20 } ];

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        const dealHeaders = Object.keys(dealsData[0]);
        let headers = [
            'Pipeline',
            'Location',
            'Quantity',
            'Position',
            'Total',
            'Total Flow:',
            ...dealHeaders
        ];

        dnData.forEach(dnD => {
            dnD['dailyNominationHeaderJson'].forEach(dnHeader => {
                const dNHeaders = Object.values(dnHeader);
                headers = [...headers, ...dNHeaders];
            });
            
        });

        Object.keys(wb['Sheets']['data']).forEach(key => {
            const val = wb['Sheets']['data'][key]['v'];
            if(headers.indexOf(val) > -1) {
                wb['Sheets']['data'][key].s = {
                    fill: {
                      patternType: "solid",
                      fgColor: { rgb: "8DD8FC" }
                    }
                };
            }

        });

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});

        FileSaver.saveAs(data, fileName + fileExtension);
    }

    const openEmailModal = () => {
        context.modal.show({
            title: 'Email',
            customForm: EmailSetup,
            formProps: { 
                flowDate: getFlowDateLabel(),
                dailyNomination: dailyNomination,
                deals: getAllDeals()
            }
        });
    }

    const sendCompanyReceiptEmail = async () => {
            try {
                const flowDateData = getFlowDateObj();                
                const res = await Axios.post('/api/deal/complete/email', { 
                    flow_date_id: flowDateData.id
                });
          
                if (res.status === 200) {
                    context.notification.show('Company receipt has been emailed.', 'success', true);
                }          
            } catch (err){
                context.handleError(err);
            }
    }

    const renderUtilPipelineRecords = () => {
        /*const triagePipelineRecords = (plrs) => {

            const locationObj = plrs.reduce((obj, curr) => {
                if (!obj[curr.location]) {
                    obj[curr.location] = curr;
                } else {
                    obj[curr.location]['quantity'] = obj[curr.location]['quantity'] + curr.quantity;
                }

                return obj;
            }, {});

            return Object.keys(locationObj).map(key => locationObj[key]);
        }*/
        
        return dailyNomination.map(dailyNom => {
            return (
                <Card title={`Utility: ${dailyNom.name}`}>
                    { getDailyNominationPipelineRecordList(dailyNom.pipelineRecords) }
                </Card>
            );
        });
    };

    const getMatchingDate = (date) => flowDateOptions.find(fD => date.isSame(moment(fD.date), 'day'));

    const hasNewSpotDealsOnChosenDate = () => {
        const flowDateObj = getFlowDateObj();

        return spotDeals.filter(sd => sd.flow_date_id === flowDateObj.id).length > 0
    };
    
    return (
        <Animate type='pop'>
            {flowDateOptions && flowDateOptions.length > 0 && 
                    
                    <Card
                        loading={dealsLoading || dailyNomLoading}
                    >
                        <p>Select a flow date to display the report for that day.</p>
                        <Row gutter={[8, 8]}>
                            <Col xl={{ span: 6 }} md={{ span: 12 }} xs={{ span: 24 }}>
                                <DatePicker 
                                    style={{ width: '100%' }}
                                    onChange={(dateObj) => onFlowDateChange(dateObj)}
                                    disabledDate={d => !getMatchingDate(d)}
                                    direction="ltr"
                                    value={selectedFlowDate ? moment(getFlowDateLabel()) : null}
                                />
                            </Col>

                            <Col xl={{ span: hasNewSpotDealsOnChosenDate() ? 10 : 8, offset: hasNewSpotDealsOnChosenDate() ? 6 : 10 }} md={{ span: 12 }} xs={{ span: 24 }}>
                                {getAllDeals().length > 0 && dailyNomination && dailyNomination.length > 0 &&
                                    <div className={Style.exportMenu}>
                                        <Space className={Style.reportMenuWrap}>
                                            <Button 
                                                onClick={ e => exportToCSV() }
                                            >
                                                Export Report
                                            </Button>
                                            
                                            <Button 
                                                onClick={ e => openEmailModal() }
                                            >
                                                Email Report
                                            </Button>
                                            {hasNewSpotDealsOnChosenDate() &&
                                                <Button 
                                                    onClick={ e => sendCompanyReceiptEmail() }
                                                >
                                                    Email Company Receipt
                                                </Button>
                                            }
                                            </Space>
                                        
                                    </div>
                                }
                            </Col>
                        </Row>
                        
                        {getAllDeals().length > 0 && dailyNomination && (!dealsLoading && !dailyNomLoading) &&
                            <>
                                <Divider />
                                <Grid cols="2">
                                    <div className={Style.flowDate}>
                                        <strong>Flow Date:</strong> { getFlowDateLabel() }
                                    </div>
                                    <div className={Style.flowTotal}>
                                        <span>
                                            <strong>Total Flow:</strong> { getPipelineQuantityTotal() }
                                        </span>
                                    </div>
                                </Grid>
                            </>
                        }
                    </Card>
            }
            {!flowDateOptions || (!flowDateOptions.length &&
                <Card>
                    You currently do not have any reports to list.
                </Card>)
            }
            {noDailyNomination &&
                <Card>
                    There is currently no daily nomination submitted for the chosen date.
                </Card>
            }
            {getAllDeals().length > 0 && dailyNomination && (!dealsLoading && !dailyNomLoading) &&
                <>
                    { renderUtilPipelineRecords() }
                    {spotDeals &&
                        <Card title="Spot Deals">
                            { getDealsList() }
                        </Card>
                    }
                    {longTermDeals &&
                        <Card title="Long Term Deals">
                            { getDealsList(true) }
                        </Card>
                    }
                </>
            }
        </Animate>
    );
}